<template>
  <div class="home-container">
    <!--轮播图 -->
    <el-carousel
      style="height: 617px; max-width: 1920px;width: 100%;min-width: 1200px;margin:0 auto;"
      :interval="5000"
      arrow="always"
      indicator-position="none"
    >
      <el-carousel-item
        style="height: 617px"
        v-for="item in bannerList"
        :key="item.id"
      >
        <div
          class="banner-item"
          :style="'background-image: url(' + item.url + ');'"
        >
          <div class="banner-conntent">
            <!-- <h4>水杉在线 一直很好</h4>
            <p>智慧教育，智能学习</p> -->
            <img src="../../assets/img/banner-title.png" />
            <span class="understand cp fs18" @click="playVideo"
              ><img
                src="../../assets/img/banner-understand.png"
              />了解更多</span
            >
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 各个部分 -->
    <div class="introductions" @mouseleave="hanldeMouseLeave">
      <div 
        class="introduction" 
        v-for="item in introductions" :key="item.title" 
        :class="item.isFirst?'first-class':''"
        @mouseenter="hanldeMouseEnter(item)"
      >
        <div class="title fw7">
          {{ item.title }}
        </div>
        <div class="explain">{{ item.subtitle }}</div>
        <div
          v-for="(intro, index) in item.content"
          :key="index"
          class="introContent"
        >
          {{ intro }}
        </div>
        <div class="get-into cp fs14">
          <div class="get-into-button-show" @click="handleEnter(item)">
            立即进入
            <img
              class="arrow-img"
              src="../../assets/img/get_into_arrow_choosing.png"
            />
          </div>
          <div class="get-into-button-hide">
            <img
              class="arrow-img"
              src="../../assets/img/get_into_arrow_not_choose.png"
            />
          </div>
        </div>
        <div class="is-image"></div>
      </div> 
    </div>

    <!-- 关于 -->
    <div class="abouts">
      <div class="about">
        <div class="title fw6">
          关于水杉在线
          <div class="line"></div>
        </div>
        <div class="words">
          “水杉在线”是华东师范大学数据科学与工程学院推出的新一代数字化全链路在线学习平台，是一个面向学生“学”、“练”、“测”、“创”一体的综合性学习社区。目前，“水杉在线“正式上线的部分包括四个主要学习模块。同时，基于平台上积累的学习行为数据，将持续拓展个性化导学、自适应学习、AI助教等智能教育应用，通过云计算、大数据、人工智能等技术手段，实现“有教无类、因材施教、寓教于乐、教学相长”的中国教育智慧。
        </div>
      </div>
      <div class="aboutVideo">
        <div class="video" @click="playVideo"></div>
      </div>
    </div>

    <!-- 为什么选择水杉服务 -->
    <div class="reasons">
      <div class="title-why-choose fw6">为什么选择水杉服务</div>
      <div class="why-choose-line"></div>
      <div class="reasonDetails">
        <div class="reason" v-for="item in reasons" :key="item.title">
          <img :src="item.img" alt="" />
          <div class="reason-title fs18">{{ item.title }}</div>
          <div class="reason-keyWords">{{ item.keyWords }}</div>
        </div>
      </div>
    </div>

    <!-- 合作企业 -->
    <div class="company">
      <div class="company-title fw6">合作企业</div>
      <div class="company-line"></div>
      <div class="dfaj company-list">
        <div
          class="company-item dfaj mr20 mb20"
          v-for="item in companyList"
          :key="item"
        >
          <img :src="item" alt="" />
        </div>
      </div>
    </div>

    <!-- 视频 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="50%"
      class="video-dialog"
    >
      <video :src="videoUrl" controls="controls" preload="auto"></video>
    </el-dialog>
  </div>
</template>
<script>
import enums from "@/util/enums";
export default {
  name: "Home",
  data() {
    return {
      currentIndex: 0,
      bannerList: enums.bannerList.homeBannerList,
      introductions: enums.introductions,
      reasons: enums.reasons,
      dialogVisible: false,
      videoUrl:
        "https://shuishan-video.oss-cn-shanghai.aliyuncs.com/Huhang/%E6%AD%A3%E5%BC%8F%E7%89%88/%E7%AC%AC%E4%BA%94%E9%83%A8%E5%88%86.mp4",
      companyList: enums.companyList,
    };
  },
  mounted() {
    this.eventBus.$on(this.$route.name, () => {
      this.tonav();
    });
  },
  beforeDestroy() {
    this.eventBus.$off(this.$route.name);
  },
  methods: {
    // 立即进入
    handleEnter(item) {
      // console.log(item)
      if (item.title == "水杉课堂") {
        this.$router.push({
            name: "school",
        });
      } else if (item.title == "水杉工坊") {
        this.$router.push("/work/workShop");
      } else if (item.title == "水杉校场") {
        this.$router.push("/question/calibrationField");
      } else if (item.title == "水杉码园") {
        this.$router.push("/codePark/codeParkDetail");
      }
    },
    // 点击播放视频
    playVideo() {
      this.dialogVisible = true;
    },
    tonav() {
      const steps = [
        {
          element: ".header-menu",
          stageBackground: "#fff",
          popover: {
            title: "导航栏",
            description: "点击查看水杉平台的各个内容模块",
            position: "right",
          },
        },
        {
          element: ".user-no-login",
          stageBackground: "transparent",
          popover: {
            title: "登录/注册入口",
            description: "点击按钮，可注册或登录本平台。",
            position: "left",
          },
        },
        {
          element: ".user-exist",
          stageBackground: "#fff",
          popover: {
            title: "用户头像",
            description: "点击可查看并管理个人信息、学习情况等。",
            position: "left",
          },
        },
        {
          element: "#sidebarComponents",
          stageBackground: "transparent",
          popover: {
            title: "悬浮按钮",
            description:
              "点击助教，可与助教在线对话，询问学习问题。点击帮助，可查看页面导览、反馈平台问题、或查看使用手册。点击“回顶部”按钮，返回页面顶部。",
            position: "left",
          },
        },
        {
          element: ".el-carousel",
          stageBackground: "transparent",
          popover: {
            title: "横幅",
            description: "系统简介，展示重要信息",
            position: "bottom",
          },
        },
        {
          element: ".introductions",
          stageBackground: "transparent",
          popover: {
            title: '模块入口',
            description: '点击可跳转查看平台主要模块',
            position: 'bottom',
          }
        },
        {
          element: ".abouts",
          stageBackground: "transparent",
          popover: {
            title: "水杉简介",
            description: "了解水杉在线能力与特点。",
            position: "top",
          },
        },
      ];
      this.Driver.defineSteps(steps); // 设置步骤
      this.Driver.start(); // 开始导航
    },
    //鼠标进入
    hanldeMouseEnter(val){
      if(val.title!="水杉课堂") {
        this.introductions[0].isFirst = false
      } else {
        this.introductions[0].isFirst = true
      }
    },
    //鼠标离开
    hanldeMouseLeave(){
      this.introductions[0].isFirst = true
    }
  },
};
</script>
<style lang="scss" scoped>
.home-container {
  width: 100%;
  // height: 1000px;
  background-color: #ffffff;

  .is-title {
    font-size: 32px;
    margin-bottom: 40px;
  }

  .banner-item {
    max-width: 1920px;
    width: 100%;
    min-width: 1200px;
    height: 616px;
    background-size: cover;
  }

  .banner-conntent {
    width: 1200px;
    height: 139px;
    color: #ffffff;
    margin: 0 auto;
    padding: 175px 24px 0px;
    text-align: left;
    img {
      width: 550px;
      height: 131px;
    }

    .understand {
      text-align: center;
      position: relative;
      top: 22px;
      left: 10px;
      display: block;
      width: 170px;
      height: 50px;
      line-height: 50px;
      font-family: PingFangSC-Regular;
      color: #ffffff;
      font-weight: 400;
      border: 1px solid #ffffff;
      border-radius: 30px;

      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }

  // 轮播图
  ::v-deep .el-carousel__indicators--horizontal {
    left: 20%;
    bottom: 10%;

    button {
      width: 18px;
      height: 6px;
    }

    .is-active button {
      height: 6px;
      width: 36px;
    }
  }

  ::v-deep .el-carousel__arrow.el-carousel__arrow--left {
    background-image: url(../../assets/img/banner-left.png);
    background-size: 100% 100%;
    margin-top: 11%;
    margin-left: 2vw;
    width: 64px;
    height: 64px;

    i::before {
      content: "";
    }
  }

  ::v-deep .el-carousel__arrow.el-carousel__arrow--right {
    background-image: url(../../assets/img/banner-right.png);
    background-size: 100% 100%;
    margin-top: 11%;
    margin-right: 2vw;
    width: 64px;
    height: 64px;

    i::before {
      content: "";
    }
  }

  .el-carousel__item {
    background-color: #99a9bf;
    text-align: center;
    font-size: 50px;
  }

  // 各个部分
  .introductions {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    padding: 0px 24px;
    .introduction {
      padding: 56px 0px 20px 39px;
      height: 348px;
      background-color: #ffffff;
      flex: 1;
      border: 1px solid #ededed;
      border-right: none;
      margin-top: -95px;
      z-index: 99;
      box-sizing: border-box;
      transition: all 0.8s linear;
      background-repeat: no-repeat;
      // background-size: 49.67% 46.83%;
      background-size: 100% 100%;
      background-position: right bottom;
      border-collapse:collapse;
      position: relative;
      .title {
        margin-bottom: 40px;
        width: 140px;
        height: 33px;
        font-size: 24px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 600;
      }
      .explain {
        display: none;
      }
      .introContent {
        width: 180px;
        height: 16px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #626262;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 400;
        margin-bottom: 15px;
      }
      .get-into {
        width: 120px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
        margin: 27.5px auto 21.5px 0px;
        .get-into-button-show {
          display: none;
        }
        .get-into-button-hide {
          text-align: left;
          display: block;
          width: 20px;
          height: 20px;
        }
        img {
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
      .is-image {
        width: 149px;
        height: 162.97px;
        background-size: 100% 100%;
        position: absolute;
        bottom: 0px;
        right: 0px;
        transition: all 0.8s linear;
        background-repeat: no-repeat;
      }
      &:nth-child(1) {
        .is-image {
          background-image: url(../../assets/img/index/tab_background_school.png);
        }  
      }
        &:nth-child(2) {
          .is-image {
            background-image: url(../../assets/img/index/tab_background_work_shop.png);
          } 
        }
        &:nth-child(3) {
          .is-image {
            background-image: url(../../assets/img/index/tab_background_question.png);
          }
        }
      
      &:nth-child(4) {
        .is-image {
          background-image: url(../../assets/img/index/tab_background_code_park.png);
        }
        border-right: 1px solid #ededed;
      }
      &:nth-child(1):hover,&.first-class {
        .is-image {
          background-image: url(../../assets/img/index/tab_background_school_active.png);
        }
      }
      &:nth-child(2):hover {
        .is-image {
          background-image: url(../../assets/img/index/tab_background_work_shop_active.png);
        }
      }
      &:nth-child(3):hover {
        .is-image {
          background-image: url(../../assets/img/index/tab_background_question_active.png);
        }
      }
      &:nth-child(4):hover {
        .is-image {
          background-image: url(../../assets/img/index/tab_background_code_park_active.png);
        }
      }
      &:hover,&.first-class {
        background-image: linear-gradient(180deg, #F1FEF8 0%, #FFFFFF 100%);
        padding: 46px 0px 20px 39px;
        transition: all 0.8s linear;
        box-shadow: 0 4px 16px  rgba(17, 108, 175, 0.2);
        .title {
          margin-bottom: 0px;
          color: #13a81b;
        }
        .explain {
          width: 224px;
          height: 32px;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #626262;
          letter-spacing: 0;
          line-height: 32px;
          font-weight: 400;
          margin-bottom: 16px;
          display: block;
        }
        .introContent {
          margin-bottom: 15px;
          cursor: pointer;
        }
        .get-into {
          background-image: linear-gradient(90deg, #1EC43F 0%, #0CA4EE 70%);
          border-radius: 6px;
          .get-into-button-show {
            display: block;
          }

          .get-into-button-hide {
            display: none;
          }
        }
      }
    }
    .introduction:first-child {
      border-radius: 16px 0px 0px 16px;
    }
    .introduction:last-child {
      border-radius: 0px 16px 16px 0px;
    }
  }

  // 关于
  .abouts {
    width: 1200px;
    height: 360px;
    margin: 110px auto;
    display: flex;
    background-image: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 31%, #DFFEEF 100%);
    border-radius: 16px;
    .about {
      padding: 44px 0px 0px 48px;
      box-sizing: border-box;
      margin-right: 96px;
      .title {
        width: 276px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold;
        color: #131313;
        letter-spacing: 0;

        .line {
          margin-top: 10px;
          width: 60px;
          height: 4px;
          background: #13A81B;
        }
      }

      .words {
        margin-top: 27px;
        width: 537px;
        height: 192px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #626262;
        letter-spacing: 0;
        line-height: 32px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .aboutVideo {
      width: 472px;
      height: 299px;
      position: relative;
      z-index: 99;
      border-radius: 16px;
      padding: 44px 46px 0px 0px;
      .video {
        width: 100%;
        height: 100%;
        background-image: url(../../assets/img/video.png);
        background-size: 100% 100%;
        border-radius: 16px;
      }
    }
  }

  // 为什么选择水杉服务
  .reasons {
    max-width: 1920px;
    width: 100%;
    min-width: 1200px;
    height: 474px;
    margin: 0 auto;
    background-image: url(../../assets/img/why_choose.jpg);
    padding-top: 59px;
    align-content: center;
    text-align: center;
    margin-top: 95px;
    background-size: 100% 100%;

    .title-why-choose {
      width: 100%;
      height: 40px;
      font-family: PingFangSC-Semibold;
      font-size: 28px;
      color: #ffffff;
      letter-spacing: 0;
    }

    .why-choose-line {
      width: 60px;
      height: 4px;
      background: #ffffff;
      margin: 22px auto 78px auto;
    }

    .reasonDetails {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      align-content: space-between;

      .reason {
        flex: 1;

        .reason-title {
          width: 90px;
          height: 25px;
          font-family: PingFangSC-Semibold;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 600;
          margin: 35px auto 13px auto;
        }

        .reason-keyWords {
          width: 217px;
          height: 40px;
          opacity: 0.8;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          margin: auto auto 76px auto;
        }

        img {
          width: 76px;
          height: 68px;
        }
      }
    }
  }

  // 合作企业
  .company {
    background: #F5F6F9;
    padding: 82px 24px 0px;
    .company-title {
      height: 40px;
      font-family: PingFangSC-Semibold;
      font-size: 28px;
      color: #151718;
      letter-spacing: 0;
      text-align: center;
    }
    .company-line {
      margin: 13px auto 39px;
      width: 60px;
      height: 4px;
      background: #13A81B;
    }
    .company-list {
      flex-wrap: wrap;
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 110px;
      .company-item {
        width: 282px;
        height: 96px;
        background: #FFFFFF;
        border-radius: 12px;
        img {
          height: 40px;
          max-width: 90%;
        }
        &:first-child {
          img {
            height: 34px;
          }
        }
        &:nth-child(2),&:nth-child(4) {
          img {
            height: 37px;
          }
        }
        &:nth-child(3), &:nth-child(11){
          img {
            height: 31px;
          }
        }
        &:nth-child(5) {
          img {
            height: 33px;
          }
        }
        &:nth-child(10), &:nth-child(14) {
          img {
            height: 35px;
          }
        }
        &:nth-child(6) {
          img {
            height: 39px;
          }
        }
        &:nth-child(7) {
          img {
            height: 24px;
          }
        }
        &:nth-child(8) {
          img {
            height: 44px;
          }
        }
        &:nth-child(9) {
          img {
            height: 36px;
          }
        }
        &:nth-child(12) {
          img {
            height: 42px;
          }
        }
        &:nth-child(15) {
          img {
            height: 38px;
          }
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  // 弹窗视频
  .el-dialog__wrapper {
    ::v-deep .el-dialog__header {
      padding-top: 0;
    }
  }
}
</style>
<style lang="scss">
.video-dialog {
  .el-dialog {
    // height: 100%!important;
    .el-dialog__body  {
      width: 100%;
      padding: 0px!important;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
 
  .el-dialog__header {
    padding: 0px!important;
  }
  .el-dialog__headerbtn {
    z-index: 999;
    font-size: 18px;
  }
}
</style>