import request from '@/util/request'
import qs from 'qs'

// 修改密码
export function usersPassword(param) {
  return request({
    url: '/user/users/password',
    method: 'put',
    data: param
  })
}

//获取个人信息
export function getUserInformation() {
  return request({
    url: '/sys/users/current',
    method: 'get',
    // params: params
  })
}

//获取通知消息
export function getUserMessage(params) {
  return request({
    url: '/resource/message',
    method: 'get',
    params: params,
    noRelogin: true
  })
}

//标记消息已读
export function markMessAsRead(ids) {
  return request({
    url: '/resource/message/marked',
    method: 'post',
    data: ids, // ✅ 直接传递数组，不要包裹成对象
    headers: { "Content-Type": "application/json" } // ✅ 指定 JSON 格式
  })
}

// 发送手机验证码
export function handleSendCode(params) {
  return request({
    url: '/portal/reset/sendPhone',
    method: 'post',
    params: params
  })
}

// 发送邮箱验证码
export function emailSendCode(params) {
  return request({
    url: '/portal/reset/sendMail',
    method: 'post',
    params: params
  })
}

// 解绑/更换手机号提交
export function checkPhoneCode(params) {
  return request({
    url: '/portal/reset/check_phone_code',
    method: 'post',
    params: params
  })
}

// 更换邮箱提交
export function checkMailCode(params) {
  return request({
    url: '/portal/reset/check_mail_code',
    method: 'post',
    params: params
  })
}

// 获取邮箱
export function getMail(params) {
  return request({
    url: '/portal/reset/getMail',
    method: 'post',
    params: params
  })
}

// 获取手机号
export function getPhone(params) {
  return request({
    url: '/portal/reset/getphone',
    method: 'post',
    params: params
  })
}

// 获取所属单位
export function getCompany(params) {
  return request({
    url: '/super/company',
    method: 'get',
    params: params
  })
}

//获取积分
export function getIntegral(params) {
  return request({
    url: '/teacher/course/sum_liveness',
    method: 'get',
    params: params
  })
}

//更改头像
export function uploadAvatar(formData) {
  return request({
    url: '/resource/avatar',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data', // 可选，浏览器会自动设置
    },
    data: formData // 使用 data 而不是 params
  })
}

//
// //获取头像
// export function getAvatar() {
//   return request({
//     url: '/resource/avatar',
//     method: 'get'
//   })
// }
