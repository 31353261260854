<template>
  <div class="system-container">
    <div class="notice-wrapper">
      <div
        class="notice-bottom pt20 pb20 fs16 cp"
        v-for="(item,index) in paginatedTodoItems"
        :key="index"
        :class="isLogin?'notice-bottom-login':''"
      >
        <div class="pb6" :class="item.status == 0 ?'':'c999999'">
          {{ item.message }}
        </div>
        <div class="dfal jsb">
          <div class="notice-time">
            {{ item.createTime }}
          </div>
          <div v-if="activeName == 'a'" :class="item.status==0?'no-look-detail':'c999999'" class="dfal" @click="goToCourseDetail(item)">
            查看详情
            <svg-icon
              icon-class="icon_chevron"
              className="arrow-right"
            />
          </div>
          <div v-if="activeName == 'b'" class="dfal" :class="item.status == 0 ?'':'c999999'">
            <svg-icon icon-class="icon_circle_left" className="icon-check-circle" v-if="item.status == 0"/>
            {{ item.status == 0 ? "标记已读" : "已读" }}
          </div>
        </div>
      </div>
    </div>
    <pagination
      :page="page"
      :limit="pageSize"
      :total="list.length"
      :hidden="list.length <= 0"
      @pagination="handleCurrentChange"
    />
    <!-- <el-pagination
      :current-page="page"
      :page-size="pageSize"
      :total="list.length"
      :hidden="list.length <= 0"
      @current-change="handleCurrentChange"
    /> -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import enums from "@/util/enums";
export default {
  name: "SystemNotification",
  props: {
    list: {
      type: Array,
      default: [],
    },
    activeName: {
      type: String,
      default: "",
    },
    isLogin: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Pagination,
  },
  data() {
    return {
      page: 1,
      pageSize: 4,
    };
  },
  mounted() {},
  computed: {
    paginatedTodoItems() {
      const start = (this.page-1) * this.pageSize;
      const end = start + this.pageSize;
      return this.list.slice(start, end);
    }
  },
  methods: {
    //分页
    handleCurrentChange(newPage) {
      this.page = newPage.page;  // 更新页码
    },
    // 跳转详情页
    goToCourseDetail(item) {
      if (!item.courseId) {
        console.error("无法跳转，缺少课程ID");
        return;
      }
      // 点击详情页后默认已读，修改当前的消息状态
      const ind = this.list.findIndex(ele => ele.id == item.id);
      if (this.list[ind].status === 0) {
        this.$emit("update-list", ind);
      }
      // 跳转到课程详情页面，假设课程详情路径为 `/course/:id`
      // 跳转到课程详情页
      this.$router.push({
        path: "/Course/CourseDetail",
        query: { courseId: item.courseId }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.system-container {
  .notice-wrapper {
    padding-bottom: 20px;
    .notice-bottom {
      width: 100%;
      border-bottom: 1px solid #e4e7ed;
      color: #303133;
      .notice-time {
        color: #999999;
      }
      &.notice-bottom-login {
        font-size: 14px;
      }
    }
  }
  .no-look-detail {
    color: #13A81B;
  }
  .arrow-right {
    width: 16px;
    margin-left: 11px;
  }
  .icon-check-circle {
    width: 18px;
    margin-right: 5px;
  }
}
</style>