<template>
  <div class="login-container">
    <div class="user-no-login dfj ml10" v-if="!studentName">
      <div class="user-none cp" @click.prevent="toLogin">
        <span>
          <a href=" ">登录</a>
        </span>
      </div>
      <div
        class="user-none user-register ml10 cp"
        @click.prevent="toRegisterTip"
      >
        <span>
          <a href=" ">注册</a>
        </span>
      </div>
    </div>
    <div class="user-exist dfal" v-if="studentName">
      <div class="dropdown">
        <div class="dropdoen-title ring-box" :class="isCenter == 'myCenter' ? 'ring-header' : ''">
          <svg-icon
            icon-class="icon_chevron-left"
            className="icon-chevron-left"
          />
          <div v-if="unreadCount > 0" class="ring-mark"></div>
        </div> 
       <div class="dropdown-content notice-wrapper pd20">
         <div class="notice-top-box">
           <el-tabs v-model="activeName">
             <el-tab-pane name="a">
                <div slot="label">
                  待办事项
                 <!-- <div class="ring-mark"></div> -->
                </div>
                <div v-if="todoItems.length === 0" class="empty-message">
                  暂无待办事项，以后再来查看吧！
                </div>
                <system-notification
                  v-else
                 :list="todoItems"
                 :activeName="activeName"
                 :isLogin="true"
                 class="is-todo-box"
                 @update-list="updateMessage" 
                ></system-notification>
             </el-tab-pane>
             <!-- <el-tab-pane label="系统通知" name="b">
               <div slot="label">
                 系统通知
                 <div class="ring-mark"></div>
               </div>
               <system-notification
                 :list="systemNoticeList"
                 :activeName="activeName"
                 :isLogin="true"
                 class="is-todo-box"
               ></system-notification>                                                                    
             </el-tab-pane> -->
           </el-tabs>
           <div class="all-read dfal fs14 cp" @click="handleGotoNotice">
             历史中心
             <svg-icon
               icon-class="icon_right_circle_filled"
               className="icon-check-circle"
             />
           </div>
         </div>
         <div class="all-is-read dfal fs14 mt10 clickable" @click="markAllAsRead">
           <svg-icon
             icon-class="icon_circle_left"
             className="icon-check-circle"
           />
           全部已读
         </div>
       </div>
      </div>
      <div class="dropdown">
        <div class="dropdoen-title">
          <img
            v-if="!headPortrait"
            class="dropdoen-title-img"
            src="../../public/imgs/avatar_blue.png"
            alt=""
          />
          <img class="dropdoen-title-img" :src="headPortrait" alt="" />
          <span :class="isCenter == 'myCenter' ? 'center-header' : ''">{{
            studentName
          }}</span>
        </div>
        <div class="dropdown-content">
          <span><a href=" " @click.prevent="toMySet">个人中心</a></span>
          <span><a href=" " @click.prevent="logout">退出登录</a></span>
        </div>
      </div>
    </div>
    <!-- 账号密码登录 -->
    <el-dialog
      :visible.sync="openLogin"
      :destroy-on-close="true"
      @close="resetQuery"
      width="400px"
      center
      class="login_box"
      :close-on-click-modal="false"
    >
      <p class="title mb40 fs24 fw6">账号登录</p>
      <el-form
        ref="passwordLoginForm"
        :model="passwordLoginForm"
        :rules="loginRules"
      >
        <el-form-item prop="username">
          <el-input
            class="login_form_input"
            v-model="passwordLoginForm.username"
            type="text"
            maxlength="32"
            auto-complete="off"
            placeholder="用户名/手机号/Email/学号"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            class="login_form_input"
            v-model="passwordLoginForm.password"
            type="password"
            maxlength="32"
            auto-complete="off"
            placeholder="密码"
            clearable
          >
          </el-input>
        </el-form-item>
        <div class="forget-box">
          <div class="forget-password cp" @click="hanldeForgetPassword">
            忘记密码
          </div>
        </div>
        <el-form-item class="login-btn">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            class="login_button_pw fw6"
            @click.native.prevent="handleLogin"
          >
            <span v-if="!loading" style="color: #ffffff">立即登录</span>
            <span v-if="loading" style="color: #ffffff">登 录 中...</span>
          </el-button>
        </el-form-item>
        <el-form-item class="checked_item mb40 fs12" prop="checked">
          <el-checkbox v-model="passwordLoginForm.checked"></el-checkbox>
          <span class="ml5"
            >我已阅读和同意<a
              href="https://help.shuishan.net.cn/student/#/user_agreement"
              target="_blank"
              >《水杉在线用户协议》</a
            ></span
          >
        </el-form-item>
        <div class="dfaj mb40">
          <div class="to-regitster mr15 cp" @click="toRegisterTip">
            用户注册
          </div>
          |
          <!-- <div class="wechat-login ml15">
            <img src="@/assets/img/icon_wechat.png" class="mr5" alt="" />
            <span>微信登录</span>
          </div> -->
          <div class="wechat-login ml15 cp"  @click="toLoginByEcnu">
            <img src="@/assets/img/ecnu.png" class="mr5" alt=""/>
            <span>华东师范大学统一登录</span>
          </div>
        </div>
        <div class="dfaj fs12 question cp" @click="toFeedback">问题反聩</div>
      </el-form>
    </el-dialog>
    <!-- 注册页面 -->
    <el-dialog
      :visible.sync="openRegister"
      :destroy-on-close="true"
      @close="resetQuery"
      center
      width="400px"
      class="login_box register-box"
      :close-on-click-modal="false"
    >
      <p class="title fw6 fs24 mb40">用户注册</p>
      <!-- 注册 -->
      <el-form ref="registerForm" :model="registerForm" :rules="registerRules">
        <el-form-item prop="phone">
          <el-input
            class="login_form_input"
            v-model="registerForm.phone"
            type="text"
            maxlength="11"
            auto-complete="new-password"
            placeholder="请输入手机号"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            class="login_form_input"
            style="width: 58.3%"
            v-model="registerForm.code"
            type="text"
            maxlength="6"
            auto-complete="new-password"
            placeholder="验证码"
          />
          <el-button
            v-if="showSendCode_rg"
            class="code_button"
            :loading="loading"
            size="medium"
            type="primary"
            @click.native.prevent="sendMsgCode_register"
          >
            <span style="color: #ffffff">发送验证码</span>
          </el-button>

          <el-button
            v-if="!showSendCode_rg"
            class="code_button"
            :loading="loading"
            size="medium"
            type="primary"
            disabled
          >
            <span style="color: #ffffff">{{ count_rg }}秒后重发</span>
          </el-button>
        </el-form-item>
        <el-form-item class="first-password" prop="firstPassword">
          <el-input
            show-password
            class="login_form_input"
            v-model="registerForm.firstPassword"
            type="password"
            auto-complete="new-password"
            placeholder="请设置密码"
          >
          </el-input>
          <p class="is-password-tip fs12">
            请设置8-20位包含数字、字母和特殊符号中至少两种组合的密码
          </p>
        </el-form-item>
        <el-form-item class="second-password" prop="password">
          <el-input
            show-password
            class="login_form_input"
            v-model="registerForm.password"
            type="password"
            auto-complete="new-password"
            placeholder="再次确认密码"
          >
          </el-input>
        </el-form-item>
        <el-form-item class="checked_item fs12 register-checked-item" prop="checked">
          <el-checkbox v-model="registerForm.checked"></el-checkbox>
          <span
            >我已阅读和同意<a
              href="https://help.shuishan.net.cn/student/#/user_agreement"
              target="_blank"
              >《水杉在线用户协议》</a
            ></span
          >
        </el-form-item>
        <el-form-item style="width: 300px">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            class="login_button_rg"
            @click.native.prevent="register"
          >
            <span style="color: #ffffff">立即注册</span>
          </el-button>
        </el-form-item>
        <div class="register-to-login">
          已有账号，去<span class="cp" @click="toLogin">登录</span>
        </div>
      </el-form>
    </el-dialog>
    <!-- 水杉意见反馈 -->
    <feedback-dialog ref="feedbackDialog"></feedback-dialog>
    <!-- 忘记密码 -->
    <forget-password-dialog
      ref="forgetPasswordDialog"
      @login="toLogin"
    ></forget-password-dialog>

    <!-- 注册提示 -->
    <register-tip
      ref="registerTip"
      @toLogin="toLogin"
      @toRegister="toRegister"
    ></register-tip>
  </div>
</template>
<script>
import { portalV2URL, oauth2ClientID } from "@/env"
import ForgetPasswordDialog from "./Login/ForgetPasswordDialog.vue";
import FeedbackDialog from "./Login/FeedbackDialog.vue";
import RegisterTip from "./Login/RegisterTip.vue";
import { mapState } from "vuex";
import { setToken, removeToken, getToken } from "@/util/auth";
import {
  handleLogin,
  handleSendCode,
  handleRegister,
  handleLoginOut,
  handleLoginByEcnu,
} from "@/api/login/login.js";
import { getUserInformation, getUserMessage, markMessAsRead} from "@/api/user/user.js";
import { eventNamesDict } from "@/util/EventNames.js";
import SystemNotification from "@/pages/Notice/SystemNotification.vue";
import enums from "@/util/enums";
import {Message} from "element-ui";
export default {
  name: "login",
  components: {
    ForgetPasswordDialog,
    FeedbackDialog,
    RegisterTip,
    SystemNotification,
  },
  data() {
    var validateCheckPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (value !== this.registerForm.firstPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var checkPassword = (rule, value, callback) => {
      const reg =
        /^(?!^\d+$)(?!^[A-Za-z]+$)(?!^[^A-Za-z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/;
      const result = reg.test(value);
      if (!value) {
        callback(new Error("请填写密码"));
      } else if (result) {
        callback();
      } else {
        callback(new Error("请确认密码格式"));
      }
    };
    return {
      receiveUserId: '',
      todoItems: enums.todoItems,
      systemNoticeList: enums.systemNoticeList,
      idsReadByClick: [],
      activeName: "a",
      userUuid: this.$cookie.get("userUuid"),
      loading: false,
      openLogin: false,
      openRegister: false,
      showSendCode_rg: true,
      count_rg: 60,
      timer_pw: null,
      loginRules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        checked: [
          {
            pattern: /true/,
            message: "需勾选同意《水杉在线用户协议》",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      registerRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        firstPassword: [
          {
            required: true,
            validator: checkPassword,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: validateCheckPassword,
            trigger: "blur",
          },
        ],
        checked: [
          {
            pattern: /true/,
            message: "需勾选同意《水杉在线用户协议》",
            trigger: "blur",
          },
        ],
      },
      registerForm: {
        phone: "",
        code: "",
        firstPassword: "",
        password: "",
        checked: false,
      },
      passwordLoginForm: {
        username: "",
        password: "",
        checked: false,
      },
      show: false,
      isCenter: "", //是否是个人中心
      reg: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      needReload: false,
      dialogTipVisible: false,
      // 使用生成的随机字符串作为 state 参数的值 OAuth配置
      host: 'https://api.ecnu.edu.cn/oauth2/authorize',
      state: '',
      responseType: 'code',
      scope: 'ECNU-Basic',
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
        // if(route.query.isLogin == 1 && !getToken()) {
        //   this.toLogin()
        // }
        if (route.matched[0].path == "/center") {
          this.isCenter = "myCenter";
        } else {
          this.isCenter = "";
        }
      },
      immediate: true,
    },
    studentName(newVal) {
      if (newVal) {
        // console.log(newVal);
        // console.log(this.receiveUserId);
        this.getMessage(); // 监听 Vuex 的 userId 变化
      }
    },
  },
  computed: {
    ...mapState(["headPortrait"]),
    ...mapState(["studentName"]),
    unreadCount() {
      return this.todoItems.filter(msg => msg.status === 0).length;
    },
  },
  created() {
    if (getToken()) {
      getUserInformation()
          .then((response) => {
            if (response.respCode == 20000) {
              let res = response.data;
              this.$store.dispatch("setAvatar", res.headImgUrl);
              this.$store.dispatch("setUsername", res.truename);
              this.$store.dispatch("setUserNumber", res.userNumber);
              this.$store.dispatch("setUserId", res.id);
              // this.receiveUserId = res.id;
            }
          })
          .catch((err) => {}); 

      // 先检查 sessionStorage 是否有数据
      const cachedData = sessionStorage.getItem('todoItems');
      if (cachedData) {
        this.todoItems = JSON.parse(cachedData);
        enums.todoItems = this.todoItems;
      } else {
        this.getMessage();
      }
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);  
    const authCode = urlParams.get('code');
    if (authCode) {
      // 发送 authCode 至后端服务器，进行令牌交换等后续认证步骤
      handleLoginByEcnu(authCode).then((response) => {
        let res = response.data;
        if (res.access_token == undefined) {
          this.loading = false;
        } else if (res.access_token != null) {
          this.openLogin = false;
          this.loading = false;
          setToken(res.access_token);
          this.removeAuthCodeFromUrl(); // 移除 URL 中的 authCode
          window.location.reload(); // 刷新
          this.getList();
        }
      }).catch((e) => {
        // ElMessage.error('登录失败，' + e);
        this.removeAuthCodeFromUrl(); // 在出现错误时也移除 authCode 以防止循环刷新
      });
    }
    this.eventBus.$on(eventNamesDict.EVENT_NEED_LOGIN, (needReload) => {
      this.toLogin();
      this.needReload = !!needReload;
    });
    this.eventBus.$on(eventNamesDict.EVENT_NEED_REGISTER, () => {
      this.toRegister();
    });
  },
  beforeDestroy() {
    this.eventBus.$off(eventNamesDict.EVENT_NEED_REGISTER);
    this.eventBus.$off(eventNamesDict.EVENT_NEED_LOGIN);
  },
  methods: {
    removeAuthCodeFromUrl() {
      const url = new URL(window.location.href);
      url.searchParams.delete('code');
      url.searchParams.delete('state');
      window.history.replaceState(null, '', url.toString());
    },
    getList() {
      getUserInformation()
        .then((response) => {
          if (response.respCode == 20000) {
            let res = response.data;
            this.$store.dispatch("setAvatar", res.headImgUrl);
            this.$store.dispatch("setUsername", res.truename);
            this.$store.dispatch("setUserNumber", res.userNumber);
            this.$store.dispatch("setUserId", res.id);
            this.receiveUserId = res.id;
            // console.log(this.receiveUserId);
            if (this.needReload) {
              window.location.reload();
            }
          }
        })
        .catch((err) => {});
    },
    async getMessage() {
      let params = {
        receiveUserId: this.receiveUserId,
      };
      await getUserMessage(params)
          .then((response) => {
              if(response.respCode == 20000) {
                // 更新通知消息
                this.todoItems = response.data;
                // 存入sessionStorage 刷新时不用再次调用接口
                sessionStorage.setItem('todoItems', JSON.stringify(response.data));
                // 写入enum.js
                enums.todoItems = this.todoItems;
              }
          })
          .catch((err) => {
            console.error("获取通知消息失败:", err);
          });
    },
    // 注册提醒
    toRegisterTip() {
      this.openLogin = false;
      this.$refs.feedbackDialog.openFeedback = false;
      console.dir(this.$refs.forgetPasswordDialog);
      console.dir(this.$refs.registerTip);
      this.$refs.registerTip.handleOpenDialog();
    },
    toLoginByEcnu() {
      this.state = this.generateState(8);
      window.location.href = `${this.host}?client_id=${oauth2ClientID}&response_type=${this.responseType}&scope=${this.scope}&redirect_uri=${portalV2URL}&state=${this.state}`;
    },
    generateState (length) {
      let result = '';
      const numbers = '0123456789';
      for (let i = 0; i < length; i++) {
        result += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }
      return result;
    },
    //弹出登录弹框
    toLogin() {
      this.loginFormClear();
      this.$refs.feedbackDialog.openFeedback = false;
      this.openRegister = false;
      this.dialogTipVisible = false;
      this.openLogin = true;
    },
    loginFormClear() {
      this.passwordLoginForm = {
        username: "",
        password: "",
        checked: false,
      };
    },
    registerFormClear() {
      this.registerForm = {
        phone: "",
        code: "",
        firstPassword: "",
        password: "",
        checked: false,
      };
    },
    //账号密码登录
    handleLogin() {
      this.$refs.passwordLoginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let studentLoginParam = this.passwordLoginForm;
          // delete studentLoginParam.checked;
          handleLogin(studentLoginParam)
            .then((response) => {
              let res = response.data;
              if (res.access_token == undefined) {
                this.loading = false;
              } else if (res.access_token != null) {
                this.openLogin = false;
                this.loading = false;
                setToken(res.access_token);
                this.getList();
                Message({
                  message: '登录成功',
                  type: 'success',
                  center: true,
                  duration: 2000
                });
              } else {
                this.loading = false;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    //跳转注册界面
    toRegister() {
      this.registerFormClear();
      this.openLogin = false;
      this.$refs.feedbackDialog.openFeedback = false;
      this.dialogTipVisible = false;
      this.openRegister = true;
    },
    // 跳转问题反馈
    toFeedback() {
      this.openLogin = false;
      this.openRegister = false;
      this.$refs.feedbackDialog.openFeedback = true;
      this.$nextTick(() => {
        this.$refs.feedbackDialog.feedbackFormClear();
      });
    },
    //注册
    register() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const { phone, code, password } = this.registerForm;
          let studentLoginParam = {
            phone,
            code,
            password,
          };
          handleRegister(studentLoginParam)
            .then((res) => {
              this.openRegister = false;
              this.loginFormClear();
              this.openLogin = true;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    //重置发送验证码倒计时
    resetMessageSendTimer() {
      clearInterval(this.timer_rg);
      this.timer_rg = null;
      this.showSendCode_rg = true;
    },
    //验证码注册发送短信
    sendMsgCode_register() {
      var phone = this.registerForm.phone;
      if (!this.reg.test(phone)) {
        this.$message.error("请输入手机号");
        return;
      }
      this.sendMsgCode(phone);
      const TIME_COUNT = 60;
      if (!this.timer_rg) {
        this.count_rg = TIME_COUNT;
        this.showSendCode_rg = false;
        this.timer_rg = setInterval(() => {
          if (this.count_rg > 0 && this.count_rg <= TIME_COUNT) {
            this.count_rg--;
          } else {
            clearInterval(this.timer_rg);
            this.timer_rg = null;
            this.showSendCode_rg = true;
          }
        }, 1000);
      }
    },
    //发送短信验证码
    sendMsgCode(phone) {
      let params = {
        phone,
      };
      handleSendCode(params)
        .then((res) => {
          if (res.respCode.startsWith('200')) {
            this.$message.success("验证码发送成功");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //跳转密码登录界面
    toPasswordLogin(code) {
      this.loginFormClear();
      this.openLogin = true;
    },
    //跳转到个人中心
    toMySet() {
      this.$router.push({
        path: "/center/index",
      });
    },
    //退出
    logout() {
      // 退出之前统一处理消息状态更改
      // try {
      //   if (this.idsReadByClick.length !== 0) {
      //     const response = markMessAsRead(this.idsReadByClick);
      //     console.log(response);
      //   }
      // } catch(error) {
      //   console.error("退出前标记已读失败", error);
      // };
      let params = {
        access_token: getToken(),
      };
      // handleLoginOut(params)
      //   .then(() => {
      // this.goindex();
      this.$store.dispatch("setUsername", "");
      this.$store.dispatch("setAvatar", "");
      this.$store.dispatch("setUserId", "");
      this.$store.dispatch("setUserNumber", "");
      removeToken();
      window.location.reload();
      // });
    },
    // 返回首页
    goindex() {
      this.$router.push({
        path: "/index",
      });
    },
    //关闭弹窗
    resetQuery() {},
    //忘记密码
    hanldeForgetPassword() {
      this.openLogin = false;
      this.$refs.forgetPasswordDialog.handleOpenDialog();
    },
    //跳转到消息通知
    handleGotoNotice() {
      this.$router.push({
        path: "/notice/noticeDetail",
      });
    },
    // 父组件更新消息状态
    updateMessage(ind) {
      this.todoItems[ind].status = 1; // 让父组件更新数据
      enums.todoItems = this.todoItems;
      sessionStorage.setItem("todoItems", JSON.stringify(this.todoItems));
      this.idsReadByClick.push(this.todoItems[ind].id);
      // console.log(this.idsReadByClick);
    },
    //标记所有消息已读
    async markAllAsRead(){
      const ids = this.todoItems
        .filter(msg => msg.status === 0)
        .map(msg => msg.id);
      // 更新列表为空 直接return
      if(ids.length === 0) return;
      try {
        const response = await markMessAsRead(ids);
        console.log(response);
        // 同步更新前端状态
        this.todoItems.forEach(msg => {
          msg.status = 1;
        });
        enums.todoItems = this.todoItems;
        // 更新存储, 防止刷新页面时出错
        sessionStorage.setItem('todoItems', JSON.stringify(this.todoItems));
      } catch(error) {
        console.error("标记已读失败", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use "./../assets/scss/base.scss";
@use "./../assets/scss/mixin.scss";

//@import './../assets/scss/config.scss';
::v-deep.login_box {
  .el-dialog__body {
    padding: 15px 50px 41px 50px;
    border-radius: 8px;
  }

  .el-form-item.el-form-item--small {
    margin-bottom: 0px;
    font-size: 0px;
  }

  .el-form-item__content {
    margin-bottom: 9px;
  }
  &.register-box {
    .el-form-item__content {
      margin-bottom: 4px;
    }
  }
}

.message {
  padding: 0;
  margin-top: 3px;
  height: 20px;
  margin-bottom: 8px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #f73b42;
  letter-spacing: 0;
  font-weight: 400;
}

// 右上角按钮
.user-none {
  display: inline-block;
  position: relative;
  width: 68px;
  height: 32px;
  line-height: 32px;
  background: #13A81B;
  border-radius: 16px;
  text-align: center;
  &.user-register {
    background-color: #fff;
    border: 1px solid #13A81B;
    border-radius: 16px;
    width: 66px;
    height: 30px;
    line-height: 30px;
    a {
      color: #13a81b;
    }
  }
  span {
    font-size: 14px;
    margin: 0 5px;
  }

  a {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;

    // &:hover {
    //   color: #ffffff;
    // }
  }
}
// 右上角按钮
.user-exist {
  min-width: 120px;
  .ring-box {
    width: 20px;
    height: 20px;
    margin-right: 26px;
    position: relative;
    .icon-chevron-left {
      width: 20px;
      height: 20px;
    }
  }
  .ring-header {
    .icon-chevron-left {
      fill: #fff;
    }
  }
  .dropdown {
    .dropdoen-title {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      height: 70px;
      min-width: 50px;
      color: #181717;
      //margin-top: 15px;
      // background: url("https://ct-wev-1302740109.cos.ap-nanjing.myqcloud.com/login-succ.png")
      //   no-repeat left;
      background-size: 30px;
      .center-header {
        color: #ffffff;
      }
      &:hover {
        cursor: pointer;
        color: #13a81b;
      }
      &:hover .center-header {
        cursor: pointer;
        color: #13a81b;
      }
      span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        // width: 100px;
        font-size: 14px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }

      .dropdoen-title-img {
        height: 30px;
        border-radius: 50%;
      }
    }

    .dropdown-content {
      display: none;
      position: absolute;
      z-index: 199;
      width: 84px;
      min-height: 80px;
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: -13px;
      box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);

      span {
        position: relative;
        height: 20px;
        line-height: 20px;
        display: block;
        text-align: center;
        margin-top: 13px;

        a {
          color: #181717;
          font-size: 14px;
          font-family: PingFangSC-Light;

          &:hover {
            color: #13a81b;
          }
        }
      }
    }
  }
}
.ring-mark {
  width: 6px;
  height: 6px;
  background: #cc3f29;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 0px;
}
.login_button_rg {
  border-radius: 3px;
  height: 44px;
  width: 300px;
  // background: #f73b42;
  margin-bottom: 16px;
}

.login-btn {
  margin-bottom: 0;
  .login_button_pw {
    border-radius: 3px;
    height: 44px;
    width: 300px;
    // background: #f73b42;
    margin-top: 14px;
  }
}
.checked_item {
  color: #181717;
  &.register-checked-item {
    margin-bottom: 10px!important;
  }
  ::v-deep .el-form-item__content {
    margin-bottom: 0;
    height: 20px;
    line-height: 20px;
    label {
      height: 20px;
    }
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: url("../assets/img/icon_checked.png") no-repeat;
    background-size: 100% 100%;
    border: none;
  }
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #13a81b;
  }
}
.to-regitster,
.wechat-login {
  color: #181717;
  img {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
}
.question {
  color: #2287ef;
}
.code_button {
  border: 0px;
  border-radius: 3px;
  height: 40px;
  width: 38.6%;
  margin-left: 3.1%;
  margin-right: 0px;
  // background: #f73b42;
}
.title {
  height: 33px;
  font-family: PingFangSC-Semibold;
  color: #181717;
  letter-spacing: 0;
  text-align: center;
}
.mb40 {
  margin-bottom: 40px;
}
.login_form_input {
  ::v-deep input {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #909399;
    border-radius: 4px;
    &::placeholder {
      color: #181717;
    }
  }
}

.forget-box {
  position: relative;
  .forget-password {
    position: absolute;
    bottom: 5px;
    right: 0;
    color: #181717;
  }
}

.clickable {
  cursor: pointer;
  transition: color 0.3s ease;
}

.clickable:hover {
  color: #007bff; /* 悬停时变蓝，可按需调整 */
}

.dropdown:hover > .dropdown-content {
  display: block !important;
}

.el-input--small ::v-deep.el-input__inner {
  border: 1px solid #a39f9f;
  border-radius: 3px;
  height: 40px;
}

.first-password {
  margin-bottom: 0;
  .is-password-tip {
    width: 293px;
    margin-top: 7px;
    line-height: 17px;
    font-family: PingFangSC-Regular;
    color: #989494;
    letter-spacing: 0px;
  }
}
.second-password {
  margin-bottom: 26px;
}
.register-to-login {
  margin-top: -16px;
  text-align: center;
  color: #181717;
  .cp {
    color: #13a81b;
  }
}
.notice-wrapper {
  right: -80px;
  width: 320px !important;
  .notice-top-box {
    width: 100%;
    padding-bottom: 17px;
    border-bottom: 1px solid #e4e7ed;
    color: #303133;
    position: relative;
    .all-read {
      position: absolute;
      top: 10px;
      right: 0px;
      color: #c1c1c2;
      .icon-check-circle {
        width: 16px;
        height: 16px;
        color: #c1c1c2;
        margin-left: 4px;
        margin-top: 1px;
      }
    }
    .ring-mark {
      top: 8px;
      right: 10px;
    }
  }
  .all-is-read {
    color: #13a81b;
    .icon-check-circle {
      width: 16px;
      height: 16px;
      color: #13a81b;
      margin-right: 6px;
    }
  }
  .notice-bottom {
    width: 100%;
    border-bottom: 1px solid #e4e7ed;
    color: #303133;
    .notice-time {
      color: #999999;
    }
  }
  .is-todo-box {
    height: 391px;
    overflow-y: auto;
  }
}
</style>
<style lang="scss">
.el-icon-close:hover:before {
  content: "\e6db";
  color: #f73b42 !important;
}

.el-link.el-link--default :hover {
  color: #f73b42;
}
.login-container {
  #tab-b {
     .ring-mark {
      top: 8px;
      right: -10px;
    }
  }
}
.empty-message {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(45deg, #ff6b6b, #f94d6a, #ff9f43, #1dd1a1, #54a0ff, #5f27cd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}


</style>
