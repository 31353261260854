<template>
  <div class="layout" @click="handleClick">
    <!-- 头部 -->
    <nav-header></nav-header>
    <!-- 内容 -->
    <div  :class="activeClass">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" /> 
    </div>
    <!-- 底部 -->
    <nav-footer></nav-footer>
    <!-- 小助手 -->
    <sidebar-components />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue'
import NavFooter from '@/components/NavFooter.vue'
import SidebarComponents from '@/components/SidebarComponents/SidebarComponents.vue'
export default {
  name: 'Layout',
  components:{
    NavHeader,
    NavFooter,
    SidebarComponents
  },
  data() {
    return {
      activeClass: "",
      show: ""
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        if (route.path == "/mooc/lessonDetails") {
          this.show = "isSmall";
        }else if(route.path =='/index'||route.path =='/work/competitionSelect'||route.path =='/program/programDetail'){
          this.show = "isHome"
        } else {
          this.show = "isFull";
        }
      },
      immediate: true,
    },
  },
  created() {
    this.reDraw()
    window.addEventListener('resize', ()=>{
      this.reDraw()
    });
  },
  methods: {
    reDraw() {
      let width = document.body.clientWidth;	
      if(this.show=='isFull'&&width<1224) {
        this.activeClass = "is-padding"
      } else {
        this.activeClass = ""
      }
    },
    //关闭弹窗
    handleClick() {
      this.showRobotCode = false
      this.helpMenCode = false
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
  }
  .is-padding {
    padding: 0px 0px 0px 24px;
  }
  &.w1200 {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
</style>
