import Vue from "vue";
import Router from "vue-router";
import Index from "./pages/index";
import Layout from "./components/Layout/index.vue";
Vue.use(Router);

const router = new Router({
  base: "/",
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    // 水杉频道
    {
      path: '/channel',
      component: Layout,
      children: [
        {
          path: '/channel',
          name: 'channel',
          component: () => import('./pages/Channel/index.vue'),
          meta: {
            title: '水杉频道'
          }
        },
        {
          path: 'nourish',
          name: 'nourish',
          component: () => import('./pages/Channel/nourish/index.vue'),
          meta: {
            title: '全民数字素养与技能'
          }
        },
        {
          path: 'dase',
          name: 'dase',
          component: () => import('./pages/Channel/dase/index.vue'),
          meta: {
            title: '数据学院精品课程'
          }
        },
        {
          path: 'digitalization',
          name: 'digitalization',
          component: () => import('./pages/Channel/digitalization'),
          meta: {
            title: '全民数字素养与技能'
          }
        },
        {
          path: 'virtuallab',
          name: 'virtuallab',
          component: () => import('./pages/Channel/virtuallab/index.vue'),
          meta: {
            title: '数据专业虚拟教研室'
          }
        },
        // {
        //   path: 'cs4all',
        //   name: 'cs4all',
        //   component: () => import('pages/Channel/cs4all'),
        //   meta: {
        //     title: 'CS4ALL虚拟教研室'
        //   }
        // },
        {
          path: 'first-class',
          name: 'first-class',
          component: () => import('./pages/Channel/firstclass/index.vue'),
          meta: {
            title: '新生计算机第一课'
          }
        },
        {
          path: 'openeducation',
          name: 'openeducation',
          component: () => import('./pages/Channel/openeducation/index.vue'),
          meta: {
            title: '开源教育'
          }
        },
        {
          path: 'fintech',
          name: 'fintech',
          component: () => import('./pages/Channel/fintech/index.vue'),
          meta: {
            title: '金融科技'
          }
        },
        {
          path: 'edutech',
          name: 'edutech',
          component: () => import('./pages/Channel/edutech/index.vue'),
          meta: {
            title: '教育科技'
          }
        },
        {
          path: 'excellentclass',
          name: 'excellentclass',
          component: () => import('./pages/Channel/excellentclass/index.vue'),
          meta: {
            title: '卓越课堂'
          }
        },
        {
          path: 'excellentclass/video',
          name: 'video',
          component: () => import('./pages/Channel/components/video.vue'),
          meta: {
            title: '卓越课堂视频页'
          }
        },
        {
          path: 'shuishan-dataset',
          name: 'shuishan-dataset',
          component: () => import('./pages/Channel/shuishan-dataset/index.vue'),
          meta: {
            title: '水杉数据集'
          }
        },
      ]
    },
    {
      path: "/",
      name: "home",
      component: Layout,
      redirect: "/home",
      children: [
        {
          path: "/home",
          name: "index",
          component: Index,
          meta: {
            title: "首页 | 水杉门户"
          }
        },
      ],
    },
    {
      path: '/oauth',
      name: 'oauth',
      component: () => import('@/components/Oauth'),
      props: (route) => ({
        token: route.query.access_token,
        redirect: route.query.redirect
      }),
      meta: {
        title: '授权页面'
      }
    },
    {
      path: '/404',
      name: 'page404',
      component: () => import('@/components/Layout'),
      meta: {
        title: '404',
        icon: '',
      },
      children: [
        {
          path: 'warning',
          name: '404warning',
          component: () => import('@/components/404'),
          meta: {
            title: '404',
            icon: ''
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/404/warning',
      meta: {
        title: '404',
        icon: '',
        permission: ['ALL']
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./pages/Login.vue"),
    },
    {
      path: "/mooc",
      name: "mooc",
      redirect: "/mooc/course/list",
      component: Layout,
      children: [
        {
          path: "school", // 水杉学堂
          name: "school",
          component: () => import("./pages/Course/School.vue"),
          meta: {
            title: "水杉学堂 | 水杉门户",
            keepAlive: false,
          }
        },
        {
          path: "course/list",
          name: "CourseList",
          component: () => import("./pages/Course/CourseList.vue"),
          meta: {
            title: "水杉学堂 | 水杉门户",
            keepAlive: false,
          }
        },
        {
          path: "course/:courseId", // 课程介绍
          name: "CourseDetail",
          component: () => import("./pages/Course/CourseDetail.vue"),
          meta: {
            title: "课程主页 | 水杉门户",
            keepAlive: false
          }
        },
        {
          path: "lessonDetails", // 课程详情
          name: 'LessonDetails',
          component: () => import("./pages/Course/LessonDetails.vue"),
          meta: {
            title: "课程详情 | 水杉门户",
            keepAlive: false
          }
        }
      ],
    },
    {
      path: "/center",
      name: "center",
      component: Layout,
      children: [
        {
          path: "index",
          name: "Info",
          component: () => import("./pages/Center/index.vue"),
          meta: {
            title: "个人中心 | 水杉门户",
            keepAlive: true
          }
        },
      ],
    },
    {
      path: "/question",
      name: "question",
      component: Layout,
      children: [
        {
          path: "questionDetail",
          name: "QuestionDetail",
          component: () => import("./pages/Question/QuestionDetail.vue"),
          meta: {
            title: "水杉校场挑战题库 | 水杉门户",
            keepAlive: false
          }
        },
        {
          path: "calibrationField",
          name: "CalibrationField",
          component: () => import("./pages/Question/index.vue"),
          meta: {
            title: "水杉校场 | 水杉门户",
            keepAlive: false
          }
        },
        {
          path: "questionSelect",
          name: "QuestionSelect",
          component: () => import("./pages/Question/QuestionSelect.vue"),
          meta: {
            title: "水杉校场挑战题库 | 水杉门户",
            keepAlive: false
          }
        },
      ],
    },
    {
      path: "/codePark",
      name: "codePark",
      component: Layout,
      children: [
        {
          path: "codeParkDetail",
          name: "codeParkDetail",
          component: () => import("./pages/CodePark/CodeParkDetail.vue"),
          meta: {
            title: "水杉码园 | 水杉门户",
            keepAlive: false
          }
        },
      ],
    },
    {
      path: "/program",
      name: "program",
      component: Layout,
      children: [
        {
          path: "programDetail",
          name: "programDetail",
          component: () => import("./pages/Program/ProgramDetail.vue"),
          meta: {
            title: "开源教育 | 水杉门户",
            keepAlive: false
          }
        },
        {
          path: "collegeDetail",
          name: "collegeDetail",
          component: () => import("./pages/Program/CollegeDetail.vue"),
          meta: {
            title: "开源教育 | 水杉门户",
            keepAlive: false
          }
        },
      ],
    },
    {
      path: "/work",
      name: "work",
      component: Layout,
      children: [
        {
          path: "workShop",
          name: "WorkShop",
          component: () => import("./pages/WorkShop/index.vue"),
          meta: {
            title: "水杉工坊 | 水杉门户",
            keepAlive: false
          }
        },
        {
          path: "workShopSelect",
          name: "WorkShopSelect",
          component: () => import("./pages/WorkShop/WorkShopSelect.vue"),
          meta: {
            title: "水杉工坊实训课程 | 水杉门户",
            keepAlive: false
          }
        },
        {
          path: "jupyterhubSelect",
          name: "JupyterhubSelect",
          component: () => import("./pages/WorkShop/JupyterhubSelect.vue"),
          meta: {
            title: "水杉 Jupyterhub 实训平台 | 水杉门户",
            keepAlive: false
          }
        },
        {
          path: "competitionSelect",
          name: "CompetitionSelect",
          component: () => import("./pages/WorkShop/CompetitionSelect.vue"),
          meta: {
            title: "水杉工坊竞赛专区 | 水杉门户",
            keepAlive: false
          }
        },
      ],
    },
    {
      path: "/notice",
      name: "notice",
      component: Layout,
      children: [
        {
          path: "noticeDetail",
          name: "noticeDetail",
          component: () => import("./pages/Notice/index.vue"),
          meta: {
            title: "通知中心",
            keepAlive: false
          }
        },
      ],
    },
  ],
});

router.beforeEach ((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '水杉在线'
  }
  next()
});

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    console.error("Navigation error:", err); // Log the error to understand what's going wrong
    return Promise.reject(err); // Ensure that the error is correctly rejected
  });
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => {
    console.error("Navigation error:", err); // Log the error to understand what's going wrong
    return Promise.reject(err); // Ensure that the error is correctly rejected
  });
};

export default router;