/*
 * @Description:
 * @Author: 星歌
 * @Date: 2020-09-29 13:33:47
 * @LastEditTime: 2020-10-15 20:03:08
 * @LastEditors: 星歌
 */
import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken,removeToken} from '@/util/auth'
import errorCode from '@/util/errorCode'
import { baseURL }  from '@/env.js'
import { eventNamesDict } from "@/util/EventNames.js";
import eventBus from './eventBus.js'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseURL,
  // withCredentials: true,
  // timeout: 10000
});
// request拦截器
service.interceptors.request.use(
  config => {
      // 如果已经登录，则携带token
      if (getToken()) {
        let token = getToken();
        if(!config.url.includes('https://studashboard.shuishan.net.cn')) {//https://studashboard.shuishan.net.cn这个域名下接口不传token
          config.headers['Authorization'] = `Bearer ${token} `;// 让每个请求携带自定义token 请根据实际情况自行修改
        }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

// 响应拦截器
service.interceptors.response.use(
    (response) => {
      // 处理 2xx 状态码请求
      const respCode = response.data.respCode || 20000;
      const respMsg = response.data.respMsg;

      if (response.status !== 200) {
        errorMsg(response.status, response.statusText);
        return Promise.reject(new Error(respMsg || response.statusText));
      } else if (!respCode.startsWith('200')) {
        errorMsg(respCode, respMsg || "请求异常");
        return Promise.reject(new Error(respMsg || "请求异常"));
      }

      // 认为到此的逻辑均为正确逻辑，若此时处于鉴权失败状态，而请求体携带 token ，则认为鉴权是成功的，重置状态
      if (store.getters.isAuthorizationFailed && getToken()) {
        store.dispatch('setAuthorizationFailed', false);
      }


      if (response.headers["content-type"] === "application/octet-stream") {
        return response;
      } else {
        return response.data;
      }
    },
    (error) => {
      // 处理非 2xx 状态码请求
      const status = error.response.status;
      const respCode = error.response.data.respCode || 50000;
      const respMsg = error.response.data.respMsg;
      const noRelogin = error.config.noRelogin;

      if (status === 401) {
        // 检查是否有 noRelogin 标识
        if(noRelogin){
          return Promise.reject(error);
        }

        // 否则触发重新登录
        handle401Error();
      } else if ([400, 403, 404, 500, 502].includes(status)) {
          if (respMsg) { errorMsg(status, respMsg); }
      } else {
        errorMsg(respCode, respMsg || "未知错误");
      }

      return Promise.reject(error);
    }
)

function handle401Error() {
  if (!store.getters.isAuthorizationFailed) {
    store.dispatch('setAuthorizationFailed', true).then(() => {
      store.dispatch('FedLogOut').then(() => {
        // 显示提示“登录过期”的弹窗
        MessageBox.confirm('登录过期，请重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          type: 'error',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false
        }).then(() => {
          // 触发重新登录
          store.dispatch('FedLogOut').then(() => {
            eventBus.$emit(eventNamesDict.EVENT_NEED_LOGIN, true);
          })
        })
      })
    })
  }
}

function errorMsg(errCode, msg) {
  Message({
    message: msg,
    type: 'error',
    customClass: 'z-max'
  });
}

export default service
